.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 0.5rem;
    height: 100vh;
    overflow: hidden;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .slide {
    position: absolute;
    width: 100%;
    height: calc(100vh - 6rem);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .mobile-image {
    display: none;
  }
  
  .arrow {
    position: absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0 0 5px #555);
    z-index: 2;
    cursor: pointer;
  }
  
  .arrow-left {
    left: 1rem;
  }
  
  .arrow-right {
    right: 1rem;
  }
  
  .indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
    z-index: 1;
  }
  
  .indicator {
    background-color: var(--primary-400);
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    margin: 0 0.2rem;
    cursor: pointer;
  }
  
  .indicator-inactive {
    background-color: grey;
  }
  
  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .text-overlay {
    position: absolute;
    bottom: var(--ss-3x);
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: white;
  }
  
  @media (max-width: 480px) {
    .carousel-heading {
      font-size: 2rem;
    }
    .image {
      display: none;
    }
    .mobile-image {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  