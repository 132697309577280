.choose-component{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--ss-3x);
}
.digital-media-image{
    width: 40%;
    height: auto;
}
.choose-text{
    width: 60%;
    display: flex;
    flex-direction: column;
}
.choose-heading{
    color: var(--primary-400);
    text-transform: uppercase;
}
.choose-supporting-text{
    font-size: 3rem;
    font-weight: 500;
}
.choose-cards{
    margin-top: var(--ss-3x);
    display: flex;
    flex-direction: column;
    gap: var(--ss-1x);
}
.choose-card{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--ss-1x);
}
.choose-card-image{
    width: 20%;
    height: auto
}
.choose-card-text{
    display: flex;
    flex-direction: column;
    gap: var(--ss-0_5x);
    width: 80%;
}
.choose-card-heading{
    font-size: 1.5rem;
    font-weight: 500;
}
.fontawesome-icon{
    color: var(--primary-400);
    font-size: var(--fs-xxl);
}
.choose-card-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-200);
    padding: var(--ss-2x);
    width: 100px;
    height: 100px;
    border-radius: 20px;
}

/* MEDIA QUERIES */
@media (max-width: 820px){
    .digital-media-image{
        display: none;
    }
    .choose-text{
        width: var(--m-screen);
    }
}
@media (max-width: 480px) {
    .choose-text{
        width: var(--s-screen);
    }
    .choose-supporting-text{
        font-size: 2rem;
    }
}