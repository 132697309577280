.card-component{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    /* background-color: var(--grey-100); */
    /* border-radius: 30px; */
    /* padding: var(--ss-3x) var(--ss-2x); */
}
.service-image{
    height: 100px;
    width: 100px;
    border-radius: 30px;
}
.choose-card-icon{
    box-shadow: var(--shawdow-small);
}
.card-text{
    margin: var(--ss-2x) auto;
    display: flex;
    flex-direction: column;
    gap: var(--ss-1x);
}
.card-text>h2{
    font-size: var(--fs-xxm);
    font-weight: 700;
}


/* MEDIA QUERIES */
@media (max-width: 480px){
    .card-text>h2{
        font-size: var(--fs-s);
        font-weight: 700;
    }
}