.navbar-component{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}
.social-media-icons{
    background-color: var(--primary-400);
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--ss-0_5x) var(--ss-2x);
}
.social-media-icons-container{
    display: flex;
    align-items: center;
    gap: var(--ss-0_5x);
}
.contact-details{
    display: flex;
    align-items: center;
    gap: var(--ss-2x);
}
.logo-contact-text{
    display: flex;
    align-items: center;
    gap: var(--ss-0_5x);
}
.navbar-bg{
    background-color: white;
    display: flex;
    justify-content: center;
    box-shadow: var(--shawdow-smallest);
    
}
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    Width:95%;
}
.logo-brand{
    display: flex;
    gap: 24px;
    align-items: center;
}
.logo{
    height: 100px;
    width: auto;
    border-radius: 50%;
}
.name{
    color: var(--grey-700);
    text-transform: uppercase;
}
.menu-items{
    list-style: none;
    display: flex;
    gap: var(--ss-3x);
}
.nav-link{
    font-weight:700;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--grey-700);

}
.mobile-nav{
    display: none;
}
.navbar-icon{
    font-size: var(--ss-2x);
}
a{
    text-decoration: none;
    color: white;
}
.menu-items li.active {
    border-bottom: 2px solid var(--primary-400);
  }

@media (max-width: 820px) {
    .name{
        display: none;
    }
    .mobile-nav{
        display: block;
        cursor: pointer;
    }
    .menu-items{
        position: absolute;
        right: 0;
        top: 100px;
        background-color: white;
        width: 0px;
        /* height: calc(100vh - 100px); */
        transition: all 0.3s ease-in;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: var(--ss-1x);
        box-shadow: var(--shawdow-largest);
    }
    .menu-items.active{
        width: 100vw;
        z-index: 2;
    }
    .menu-items li {
        margin-right: unset;
        margin-top: 22px;
    }
    .menu-items>a{
        display: none;
    }
    .social-media-icons{
        display: none;
    }
    #nav-contact{
        margin-bottom: var(--ss-2x);
    }
  }